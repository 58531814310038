<template>
  <div>
    <h1 class=" my-3 px-3 p-sm-0 py-0 py-md-3 page-heading w-100">Additional terms for AnyTaskProtection&#8482; </h1>
    <div id="terms" class="container bg-white pt-2 px-4 text-left pb-3">
      <p><u>Buyers and Sellers are protected till the Task is completed.</u></p>
      <p><b>Buyer Payments in fiat:</b></p>
      <ol class="mb-5 pb-5">
        <li>The AnyTask Payment system enables a Buyer to make a payment through the Payment Services Provider using the technology platform.</li>
        <li>The Payment Services Provider sends the Task Fee to the AnyTask™ Platform.</li>
        <li>The AnyTask™ Platform now holds the Task Fee, pending the successful completion of the Task by the Seller and confirmation by the Buyer that the Task has been completed.</li>
        <li>The Buyer will be unable to reverse or cancel the contract while the Seller is working on the Task; and the duration for which the Task Fee for payment of the Task is held by the AnyTask™ Platform is the agreed date of delivery of the Task (“Task Date”) plus 3 days and if the Buyer does not lodge a formal complaint from the Task Date until the expiry of the 3 days and the Seller has sent the digital files the Task Fee will automatically be released to the Seller.</li>
        <li>We have no liability to the Buyer or the Seller if they are not using the AnyTask Payment functionality.</li>
        <li>Each AnyTask Payment is subject to the payment of a platform usage fee in addition to the Task Fees relating to any specific Task.</li>

        <p><b>Buyer Payments in ETN:</b></p>

        <li>
          The AnyTask Payment system process executes a smart contract using the technology platform.
          <ol>
            <li>“AnyTask Payment System” is a payment mechanism through the ETN App/ETN Wallet which enables a Buyer to transfer ETN from their ETN Wallet directly to a Seller’s ETN Wallet and the Buyer will be notified when the transfer will take place (“AnyTask Payment”). The AnyTask Payment System can only be used between ETN Wallets (powered by ETN-CWP) and cannot be used when transferring ETN to an offline wallet or to a third party wallet (e.g. to an exchange).</li>
            <li>To make an AnyTask Payment, the intended Seller of the Task must use the ETN App to display a QR Code which identifies the Seller’s ETN Wallet and the ETN App indicates the amount of ETN to be transferred. When the Buyer scans the QR Code using the ETN App they will be asked to confirm they want to proceed with the smart contract. Confirmation by the Buyer that they want to proceed with the smart contract is their irrevocable instruction (to us) and confirmation and acceptance to the Seller through the platform that:
              <ol>
                <li>the Buyer’s ETN Wallet can be automatically checked to confirm there is sufficient ETN in the ETN Wallet;</li>
                <li>the amount of ETN to be transferred by the Buyer will be locked meaning the Buyer will be unable to reverse or cancel the smart contract while the Seller is working on the Task; and</li>
                <li>the duration for which the amount of ETN for payment of the Task is locked is the agreed date of delivery of the Task (“Task Date”) plus 3 days AND IF the Buyer does not lodge a formal complaint from the Task Date until the expiry of the 3 days AND the Seller has sent the digital files the sending of the ETN will automatically be executed.</li>
              </ol>
            </li>
            <li>If the Buyer does not have sufficient ETN in their ETN Wallet, the AnyTask Payment will fail.</li>
            <li>If the platform does not show the Seller’s QR code for their ETN Wallet or the Seller does not send the digital files via the AnyTask™ Platform by the Task Date, the AnyTask Payment will automatically fail and the Buyer’s ETN will be unlocked.</li>
            <li>The AnyTask Payment System automatically executes a smart contract directly between Buyer and Seller according to parameters agreed by them using the platform.</li>
            <li>When you make an AnyTask Payment, we will share your email address with the recipient of the ETN to facilitate the payment and to help the recipient to resolve any potential disputes.</li>
            <li>If you are a Seller, whether you are using the AnyTask Payment System to receive payment in return for goods and/or services offered by you or accepting ETN in any other way, you agree to make available (and keep up-to-date) from time to time certain details about your business which will be listed in an information directory created and maintained by us (Seller Directory) and promoted (at our discretion) under the “ETN Everywhere” brand (or as we otherwise determine from time to time).</li>
            <li>We have no liability to the Buyer or the Seller if they are not using the AnyTask Payment functionality.</li>
            <li>Each AnyTask Payment is subject to the payment of a platform usage fee in addition to the Task Fees relating to any specific Task.</li>
          </ol>
        </li>
      </ol>
    </div>
  </div>

</template>

<script>
export default {
  name: 'AnyTaskProtection'
}
</script>

<style scoped>
#terms {
  text-align: left;
  margin-top: 1px;
  margin-bottom: 4rem;
}
#terms h1 {
  text-align: center;
}
#terms ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}
#terms ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}
#terms ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}
#terms li ol > li {
  margin: 0.5rem 0 0.5rem 0;
}
#terms li ol > li:before {
  content: counters(item, ".") " ";
}
ul li {
  list-style: disc;
}
</style>
